.customized-tooltip-content,
.recharts-tooltip-wrapper {
  color: #333;
}

.recharts-tooltip-wrapper {
  padding: .5rem 1rem;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 4px 4px 5px -1px rgba(0,0,0,0.2);
}

.recharts-default-tooltip {
  background-color: transparent !important;
  border: 0 !important;
}

.list,
.recharts-tooltip-item-list {
  padding-left: 0;
  list-style: none;
  font-weight: 700;
  margin-top: .5rem !important;
}

.list li {
  margin-bottom: .5rem;
}

.recharts-tooltip-label,
.total {
  font-size: 1.3rem;
  color: #333;
}
